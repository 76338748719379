import React, { useRef, useState, useEffect } from 'react';
import classNames from 'classnames';
import ReactDOM from 'react-dom';
import { useTranslation } from '@wix/yoshi-flow-editor';
import useFontClassName from '../../hooks/use-font-class-name';
import useLayoutColorClasses from '../../hooks/use-layout-color-classes';
import { isSite as isSiteSelector } from '../../store/basic-params/basic-params-selectors';
import FocusTrap from '../focus-trap';
import MoreIcon from '../icons/more-icon';
import PopoverRoot from '../popovers/popover-root';
import { useSelector } from '../runtime-context';
import { MoreButtonContext } from './more-button-context';
import { useGetContainerPosition } from './more-button-hook';
import styles from './more-button.scss';

type MoreButtonProps = {
  children: () => React.ReactNode | Promise<React.ReactNode>;
  className?: string;
  isWhite?: boolean;
  id?: string;
  icon?: React.ReactNode;
  container?: HTMLElement;
};

export const MoreButton: React.FC<MoreButtonProps> = ({
  children,
  className: buttonClassName,
  isWhite,
  id,
  icon,
  container,
}) => {
  const containerRef = useRef<HTMLButtonElement | null>(null);
  const actionsContainerRef = useRef<HTMLDivElement | null>(null);
  const [isVisible, setIsVisible] = useState(false);
  const [component, setComponent] = useState<React.ReactNode | null>(null);
  const { t } = useTranslation();

  const { contentFontClassName } = useFontClassName();
  const { iconColorClassName } = useLayoutColorClasses();
  const { componentId, isSite } = useSelector((state, host) => ({
    isSite: isSiteSelector(state),
    componentId: host.id,
  }));

  const {
    checkIfFits,
    checkIfFitsInEditor,
    calculateActionsContainerPosition,
  } = useGetContainerPosition({
    actionsContainerRef,
    containerRef,
    componentId,
    container,
  });

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    if (isVisible) {
      hideComponent();
      return;
    }
    showComponent();
  };

  const showComponent = async () => {
    const renderedComponent =
      children() instanceof Promise ? await children() : children();
    setComponent(renderedComponent);
    document.addEventListener('click', hideComponent);
    setIsVisible(true);
    isSite ? checkIfFits() : checkIfFitsInEditor();
  };

  const hideComponent = () => {
    document.removeEventListener('click', hideComponent);
    setIsVisible(false);
  };

  useEffect(() => {
    return () => {
      document.removeEventListener('click', hideComponent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderActionsContainer = () => {
    if (!isVisible) {
      return null;
    }

    const actionsContainerClass = classNames(
      styles.actions,
      contentFontClassName,
      'blog-text-color',
      'blog-dropdown-background-color',
    );

    const position = calculateActionsContainerPosition();

    return ReactDOM.createPortal(
      <div
        ref={actionsContainerRef}
        className={actionsContainerClass}
        style={position}
        role="menu"
        data-hook="actions"
      >
        <FocusTrap active={isVisible} onExit={hideComponent}>
          {component}
        </FocusTrap>
      </div>,
      container ?? PopoverRoot.getPopoverRootElement()!,
    );
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    if (e.key === 'Escape') {
      hideComponent();
    }
  };

  const className = classNames(buttonClassName, styles.more, 'more-button', {
    [styles.moreWhite]: isWhite,
  });

  return (
    <MoreButtonContext.Provider value={{ hideComponent }}>
      <button
        onClick={handleClick}
        className={className}
        ref={containerRef}
        onKeyUp={handleKeyUp}
        type="button"
        aria-pressed={isVisible}
        aria-label={t('more-button.more-actions')}
        id={id}
        data-hook="more-button"
      >
        <span className={styles.icon}>
          {icon || (
            <MoreIcon
              className={classNames(
                iconColorClassName,
                'blog-post-homepage-link-hashtag-hover-fill',
              )}
            />
          )}
        </span>
      </button>
      {renderActionsContainer()}
    </MoreButtonContext.Provider>
  );
};

export default MoreButton;
